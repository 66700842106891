@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: Roboto-Black;
  src: url(./fonts/Roboto-Black.ttf);
}
body {
  font-smooth: always;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.lds-default {
  display: inline-block;
  position: absolute;
  z-index: 9999;
  width: 5vw;
  height: 5vw;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

/* CMS Products table*/
.cms-table {
  width: 100%;
  text-align: center;
  font-family: "Arial, Helvetica, sans-serif";
  border-collapse: collapse;
}
.cms-table th {
  padding: 15px;
}
.cms-table td {
  padding: 12px;
}
.cms-table thead {
  position: sticky;
  top: 0;
}
.cms-table thead th {
  background-color: #54585d;
  color: #ffffff;
  border: 1px solid #9c9c9c;
}
.cms-table tbody td {
  color: #2c2c2c;
  border: 1px solid #dddfe1;
}
.cms-table tbody tr {
  background-color: #ececec;
  cursor: pointer;
}
.cms-table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}
.cms-table tbody tr:hover {
  background-color: #e0e0e0;
}
