.hello{
    animation: fadeOut 2s forwards;
    animation-delay: 26s;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}