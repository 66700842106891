
  .fadecycle img {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
    /* animation-name: imagefade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 4s; */
  }
  .fadecycle > * {
    opacity: 0;
    position: absolute;
    width: 200px;
    line-height: 200px;
    text-align: center;
}
.fadecycle > *:nth-child(1) {
    animation: fadeinout1 12s linear 0s infinite;
}
.fadecycle > *:nth-child(2) {
    animation: fadeinout2 12s linear 0s infinite;
}
.fadecycle > *:nth-child(3) {
    animation: fadeinout3 12s linear 0s infinite;
}
.fadecycle > *:nth-child(4) {
    animation: fadeinout4 12s linear 0s infinite;
}
@keyframes fadeinout1 {
    0% {
        opacity: 1;
   }
  
    25% {
        opacity: 1;
   }
    50% {
        opacity: 1;
   }
    75% {
        opacity: 1;
   }
    100% {
        opacity: 1;
   }
}
@keyframes fadeinout2 {
    0% {
        opacity: 0;
   }
   22%{
        opacity :0
   }
    25% {
        opacity: 1;
   }
    50% {
        opacity: 1;
   }
   51%{opacity:0}
    75% {
        opacity: 0;
   }
    100% {
        opacity: 0;
   }
}
@keyframes fadeinout3 {
    0% {
        opacity: 0;
   }
    25% {
        opacity: 0;
   }
   47%{
        opacity :0
   }
    50% {
        opacity: 1;
   }
    75% {
        opacity: 1;
   }
   76%{opacity:0}
    100% {
        opacity: 0;
   }
}
@keyframes fadeinout4 {
    0% {
        opacity: 0;
   }
   12%{
       opacity: 0;
   }
    25% {
        opacity: 0;
   }
    50% {
        opacity: 0;
   }
   72%{
       opacity: 0
   }
    75% {
        opacity: 1;
   }
   97%{
       opacity: 1
   }
    100% {
        opacity: 0;
   }
}
